import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { jwtDecode } from 'jwt-decode';

const name = 'auth';

const initialState = {
  user: {},
  role: null,
  department: null,
  token: null,
};

const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      const decodedToken = jwtDecode(payload.token);

      state.token = payload.token;
      state.user = {
        id: decodedToken.id,
        username: decodedToken.username,
        name: decodedToken.fullName,
        phone: decodedToken.mobilePhone,
        status: decodedToken.status,
      };
      state.role = decodedToken.role;
      state.department = decodedToken.department;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.role = initialState.role;
      state.token = initialState.token;
      state.department = initialState.department;
    },
  },
});

const getAuthState = (state) => state[name];

export const authSelector = {
  getUser: createSelector(getAuthState, (authState) => authState.user),
  getRole: createSelector(getAuthState, (authState) => authState.role),
  getDepartment: createSelector(getAuthState, (authState) => authState.department),
  getToken: createSelector(getAuthState, (authState) => authState.token),
};

export const authActions = authSlice.actions;

export default authSlice.reducer;
