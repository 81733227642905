export const USER_ROLES = {
  ARBITER: 3,
  ADMIN: 2,
  USER: 1,
};

export const PATHS = {
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  UNAUTHORIZED: '/unauthorized',
  HOME: '/',
  SELLER: '/seller',
  SELLER_DETAIL: '/seller-detail',
  PRODUCT: '/product',
  INVENTORY: '/inventory',
  RECONCILIATION: '/reconciliation',
  RECONCILIATION_DETAIL: '/reconciliation/:id',
  ECOMMERCE: {
    PRODUCT_BY_MONTH: 'ecommerce/product-by-month',
    PRODUCT_BY_SELLER_PRICE: 'ecommerce/product-by-seller-price-price',
  },
  REPORT: {
    ORDER_NOTIFY: '/report/order-notify',
    SALE_PERFORMANCE: '/report/sale-performance',
    SALE_PERFORMANCE_DETAIL: '/report/sale-performance/:saleId',
    SALE_ORDER_INVOICE: '/report/sale-order-invoice',
    SALE_ORDER_INVOICE_DETAIL: '/report/sale-order-invoice/:saleId',
  },
  ADV: {
    ORDER_NOTIFY: '/adv/order-notify',
  },
  CONFIG: {
    SYNC: '/config/sync',
  },
  HRM: {
    LEAVE: '/hrm/leave',
    CALENDAR: '/hrm/calendar',
  },
};
