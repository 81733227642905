import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PATHS, USER_ROLES } from './@constants';
import UserLayout from './layouts/UserLayout';
import FullLayout from './layouts/FullLayout';

// const LoginPage = lazy(() => import('./pages/LoginPage'));
const LoginPage = lazy(() => import('./pages/auth/login'));
const ChangePasswordPage = lazy(() => import('./pages/auth/change-password'));
const UnauthorizedPage = lazy(() => import('./pages/UnauthorizedPage'));

//admin
// const SellersPage = la zy(() => import('./pages/SellersPage'));
const SellersPage = lazy(() => import('./pages/sellers'));

// const SellerDetailPage = lazy(() => import('./pages/SellerDetailPage'));
const SellerDetailPage = lazy(() => import('./pages/seller-detail'));

// const ProductsPage = lazy(() => import('./pages/ProductsPage'));
const ProductsPage = lazy(() => import('./pages/product'));

// const InventoryPage = lazy(() => import('./pages/InventoryPage'));
const InventoryPage = lazy(() => import('./pages/inventory'));

// const ReconciliationPage = lazy(() => import('./pages/ReconciliationPage'));
const ReconciliationPage = lazy(() => import('./pages/reconciliation'));

// const ReconciliationDetailPage = lazy(() => import('./pages/ReconciliationDetailPage'));
const ReconciliationDetailPage = lazy(() => import('./pages/reconciliation-detail'));
const ProductByMonthPage = lazy(() => import('./pages/ecommerce/product-by-month'));
const ProductBySellerPricePage = lazy(() => import('./pages/ecommerce/product-by-seller-price'));

//user - admin
const HomePage = lazy(() => import('./pages/home'));

const OrderNotifyPage = lazy(() => import('./pages/report/order-notify'));
const OrderReportPage = lazy(() => import('./pages/adv/order-report'));

// const SalePerformancePage = lazy(() => import('./pages/report/SalePerformancePage'));
const SalePerformancePage = lazy(() => import('./pages/report/sale-performance'));

// const SalePerformanceDetailPage = lazy(() => import('./pages/report/SalePerformanceDetailPage'));
const SalePerformanceDetailPage = lazy(() => import('./pages/report/sale-performance-detail'));

// const SaleOrderInvoicePage = lazy(() => import('./pages/report/SaleOrderInvoicePage'));
const SaleOrderInvoicePage = lazy(() => import('./pages/report/sale-order-invoice'));

// const SaleOrderInvoiceDetailPage = lazy(() => import('./pages/report/SaleOrderInvoiceDetailPage'));
const SaleOrderInvoiceDetailPage = lazy(() => import('./pages/report/sale-order-invoice-detail'));

const ConfigSyncPage = lazy(() => import('./pages/config/sync'));

const HrmLeavePage = lazy(() => import('./pages/hrm/leave'));
const HrmCalendarPage = lazy(() => import('./pages/hrm/calendar'));

const routes = createBrowserRouter([
  {
    path: PATHS.LOGIN,
    element: <LoginPage />,
  },
  {
    path: PATHS.UNAUTHORIZED,
    element: <UnauthorizedPage />,
  },
  {
    element: <FullLayout requireRoles={[USER_ROLES.ADMIN, USER_ROLES.ARBITER]} />,
    children: [
      {
        path: PATHS.ADV.ORDER_NOTIFY,
        element: <OrderReportPage />,
      },
    ],
  },
  {
    element: <FullLayout requireRoles={[USER_ROLES.ADMIN]} />,
    children: [
      {
        path: PATHS.ECOMMERCE.PRODUCT_BY_SELLER_PRICE,
        element: <ProductBySellerPricePage />,
      },
    ],
  },
  {
    element: <UserLayout requireRoles={[USER_ROLES.ADMIN, USER_ROLES.USER]} />,
    children: [
      {
        path: PATHS.CHANGE_PASSWORD,
        element: <ChangePasswordPage />,
      },
      {
        element: <HomePage />,
        path: PATHS.HOME,
      },
      {
        path: PATHS.REPORT.ORDER_NOTIFY,
        element: <OrderNotifyPage />,
      },
      {
        path: PATHS.REPORT.SALE_PERFORMANCE,
        element: <SalePerformancePage />,
      },
      {
        path: PATHS.REPORT.SALE_PERFORMANCE_DETAIL,
        element: <SalePerformanceDetailPage />,
      },
      {
        path: PATHS.REPORT.SALE_ORDER_INVOICE,
        element: <SaleOrderInvoicePage />,
      },
      {
        path: PATHS.REPORT.SALE_ORDER_INVOICE_DETAIL,
        element: <SaleOrderInvoiceDetailPage />,
      },
    ],
  },
  {
    element: <UserLayout requireRoles={[USER_ROLES.ADMIN]} />,
    children: [
      {
        path: PATHS.SELLER,
        element: <SellersPage />,
      },
      {
        path: PATHS.SELLER_DETAIL,
        element: <SellerDetailPage />,
      },
      {
        path: PATHS.PRODUCT,
        element: <ProductsPage />,
      },
      {
        path: PATHS.INVENTORY,
        element: <InventoryPage />,
      },
      {
        path: PATHS.RECONCILIATION,
        element: <ReconciliationPage />,
      },
      {
        path: PATHS.RECONCILIATION_DETAIL,
        element: <ReconciliationDetailPage />,
      },
      {
        path: PATHS.ECOMMERCE.PRODUCT_BY_MONTH,
        element: <ProductByMonthPage />,
      },
    ],
  },
  {
    element: <UserLayout requireRoles={[USER_ROLES.ADMIN]} />,
    children: [
      {
        path: PATHS.CONFIG.SYNC,
        element: <ConfigSyncPage />,
      },
    ],
  },
  {
    element: <UserLayout requireRoles={[USER_ROLES.ADMIN, USER_ROLES.USER]} />,
    children: [
      {
        path: PATHS.HRM.LEAVE,
        element: <HrmLeavePage />,
      },
      {
        path: PATHS.HRM.CALENDAR,
        element: <HrmCalendarPage />,
      },
    ],
  },
]);

export default routes;
