import useAuth from 'hooks/useAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropsTypes from 'prop-types';
import { Layout } from 'antd';
import Navbar from '../components/Navbar';

const { Content } = Layout;

const FullLayout = ({ requireRoles = [] }) => {
  const { user, token, role, logout } = useAuth();
  const hasAccess = token && requireRoles.includes(role);
  const location = useLocation();

  if (!hasAccess) {
    logout();
    return <Navigate to="/login" />;
  }

  if (user.status === -1 && location.pathname !== '/change-password') {
    return <Navigate to="/change-password" />;
  }

  return (
    <Layout className="min-h-screen">
      <Navbar />
      <Layout>
        <Content className="max-w-full">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

FullLayout.propTypes = {
  requireRoles: PropsTypes.array,
};

export default FullLayout;
