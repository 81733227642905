import { store } from '../@redux/store';
import { API } from './axios';

export const getToken = () => {
  return store.getState()?.auth?.token;
};

export const login = async ({ username, password, ignoreDefaultErrorHandler }) => {
  return await API.post(
    'auth/login',
    {
      username,
      password,
    },
    {
      ignoreDefaultErrorHandler,
    }
  );
};

export const changePassword = async ({ username, oldPassword, newPassword }) => {
  return await API.post('auth/change-password', {
    username,
    oldPassword,
    newPassword,
  });
};
