import axios from 'axios';
import { getToken } from './auth.service';
import { notification } from 'antd';

function getConfig(config) {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
}

const apiServiceInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});
apiServiceInstance.interceptors.request.use(getConfig);
apiServiceInstance.interceptors.response.use(
  (response) => {
    if (response.config.notifyOnSuccess) {
      notification.success({
        message: 'Success',
        description: response.config.successMessage || 'Request was successful.',
        duration: 5,
      });
    }
    return response;
  },
  (error) => {
    if (!error.config.ignoreDefaultErrorHandler) {
      if (error.response.status === 401) {
        window.location.href = '/login';
      } else {
        // Handle errors
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message || 'Something went wrong. Please try again later.',
          duration: 5,
        });
      }
    }
    return Promise.reject(error);
  }
);

const crudServiceInstance = axios.create({
  baseURL: process.env.REACT_APP_CRUD_SERVICE_API_URL,
  timeout: 10000,
});
crudServiceInstance.interceptors.request.use(getConfig);

const thuocSiServiceInstance = axios.create({
  baseURL: process.env.REACT_APP_THUOCSI_API_URL,
  timeout: 10000,
});
thuocSiServiceInstance.interceptors.request.use(getConfig);

const kiotVietServiceInstance = axios.create({
  baseURL: process.env.REACT_APP_KIOTVIET_API_URL,
  timeout: 10000,
});
kiotVietServiceInstance.interceptors.request.use(getConfig);

export { apiServiceInstance as API, crudServiceInstance as crudApi, thuocSiServiceInstance as thuocSiApi, kiotVietServiceInstance as kiotVietApi };
