import { useState, useEffect } from 'react';
import { Button, Drawer, Layout, Typography, Space } from 'antd';
import useAuth from 'hooks/useAuth';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;

const Navbar = () => {
  const { user, logout } = useAuth();
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Header className={`navbar ${isMobile ? 'hidden' : 'flex'} items-center justify-between w-full z-50`}>
        <Link to="/" className="text-white">
          <Title level={4} className="text-white p-0 m-0">
            Công ty TNHH Thương mại DƯỢC PHẨM VIỆT NHẬT
          </Title>
        </Link>
        <Space>
          <Title level={5} className="text-white m-0">
            {user?.name}
          </Title>
          <Button onClick={logout} icon={<LogoutOutlined />}>
            Logout
          </Button>
        </Space>
      </Header>
      {isMobile && <Button className="menu-button" icon={<MenuOutlined />} onClick={showDrawer} />}
      <Drawer title="DƯỢC PHẨM VIỆT NHẬT" placement="left" onClose={closeDrawer} visible={visible} className="sidebar-drawer">
        <Link to="/" className="text-black" onClick={closeDrawer}>
          <Title level={4} className="text-black p-0 m-0">
            Công ty TNHH Thương mại DƯỢC PHẨM VIỆT NHẬT
          </Title>
        </Link>
        <Title level={5} className="m-0">
          {user?.name}
        </Title>
        <Button onClick={logout} icon={<LogoutOutlined />} block>
          Logout
        </Button>
      </Drawer>
    </>
  );
};

export default Navbar;
