import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services';
import { authActions, authSelector } from '@redux/authSlice';
import { notification } from 'antd';

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(authSelector.getUser);
  const role = useSelector(authSelector.getRole);
  const department = useSelector(authSelector.getDepartment);
  const token = useSelector(authSelector.getToken);

  const loginMutation = useMutation({
    mutationFn: ({ username, password }) => authService.login({ username, password, ignoreDefaultErrorHandler: true }),
    onSuccess: (response) => {
      dispatch(authActions.setToken(response?.data));
      document.cookie = `authToken=${response?.data.token}; SameSite=None; Secure`;
      navigate('/');
    },
    onError: (error) => {
      notification.error({
        message: 'Login Failed',
        description: error.response?.data?.message || error.message || 'Please check your credentials and try again.',
        duration: 5,
      });
    },
  });

  const login = ({ username, password }) => {
    loginMutation.mutate({ username, password });
  };

  const logout = () => {
    dispatch(authActions.logout());
  };

  const changePasswordMutation = useMutation({
    mutationFn: ({ username, currentPassword, newPassword }) =>
      authService.changePassword({ username: user.username, oldPassword: currentPassword, newPassword, ignoreDefaultErrorHandler: true }),
    onSuccess: (response) => {
      notification.success({
        message: 'Success',
        description: 'Password changed successfully.',
        duration: 5,
      });
      navigate('/login');
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || error.message || 'Failed to change password.',
        duration: 5,
      });
    },
  });

  const changePassword = ({ currentPassword, newPassword }) => {
    changePasswordMutation.mutate({ currentPassword, newPassword });
  };

  return {
    user,
    role,
    department,
    token,
    logout,
    login,
    isLogging: loginMutation.isLoading,
    changePassword,
    isChangingPassword: changePasswordMutation.isLoading,
  };
};

export default useAuth;
