import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSellers = createAsyncThunk('sellers/fetchSellers', async () => {
  const url = new URL(`${process.env.REACT_APP_CRUD_SERVICE_API_URL}/crud/thuocsi/seller/find-by`);
  const options = {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error('Failed to fetch sellers');
  }
  const data = await response.json();
  return data.filter((seller) => seller.priority <= 20);
});

const name = 'sellers';
const sellersSlice = createSlice({
  name,
  initialState: {
    list: [],
    selected: [],
    status: 'idle',
  },
  reducers: {
    selectSeller: (state, action) => {
      state.selected = action.payload;
    },
    setSellers: (state, { payload }) => {
      state.list = payload;
    },
    deselectSeller: (state, action) => {
      state.selected = state.selected.filter((seller) => seller.id !== action.payload.id);
    },
  },
});

const getSellerState = (state) => state[name];

export const sellersSelector = {
  getSellers: (state) => getSellerState(state).list,
  getSelectedSellers: (state) => getSellerState(state).selected,
  getStatus: (state) => getSellerState(state).status,
};

export const { selectSeller, setSellers, deselectSeller } = sellersSlice.actions;

export default sellersSlice.reducer;
