import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleProvider } from '@ant-design/cssinjs';

import Loading from 'layouts/Loading';
import { store, persistor } from '@redux/store';
import routes from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
});

const App = () => {
  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <StyleProvider layer>
            <Suspense fallback={<Loading />}>
              <RouterProvider router={routes} />
            </Suspense>
          </StyleProvider>
        </PersistGate>
      </QueryClientProvider>
    </ReduxProvider>
  );
};

export default App;
