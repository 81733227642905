import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import sellersReducer from './sellersSlice';
import dateRangeReducer from './dateRangeSlice';
import authReducer from './authSlice';

const reducers = combineReducers({
  sellers: persistReducer({ key: 'sellers', storage, whitelist: ['list', 'selected'] }, sellersReducer),
  dateRange: dateRangeReducer,
  auth: persistReducer({ key: 'auth', storage, blacklist: [''] }, authReducer),
});

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
