import { createSlice } from '@reduxjs/toolkit';
import { subDays, startOfWeek, startOfMonth, endOfWeek, endOfMonth } from 'date-fns';

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState: {
    fromDate: '',
    toDate: '',
  },
  reducers: {
    setDateRange: (state, action) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setToday: (state) => {
      state.fromDate = new Date();
      state.toDate = new Date();
    },
    setYesterday: (state) => {
      state.fromDate = subDays(new Date(), 1);
      state.toDate = subDays(new Date(), 1);
    },
    setThisWeek: (state) => {
      state.fromDate = startOfWeek(new Date());
      state.toDate = endOfWeek(new Date());
    },
    setLastWeek: (state) => {
      state.fromDate = startOfWeek(subDays(new Date(), 7));
      state.toDate = endOfWeek(subDays(new Date(), 7));
    },
    setThisMonth: (state) => {
      state.fromDate = startOfMonth(new Date());
      state.toDate = endOfMonth(new Date());
    },
    setLastMonth: (state) => {
      state.fromDate = startOfMonth(subDays(new Date(), 30));
      state.toDate = endOfMonth(subDays(new Date(), 30));
    },
  },
});

export const { setDateRange, setToday, setYesterday, setThisWeek, setLastWeek, setThisMonth, setLastMonth } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
